@use "../../sass/abstracts/variables" as v;
@use '../../sass/abstracts/mixins' as m;

.header {
    background-color: v.$background-color-primary;
    padding: 1rem 1.5rem;

    &__inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include m.respond(phone) {
            flex-wrap: wrap;
        }

    }

    &__buttons {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        @include m.respond(phone) {
            flex-wrap: wrap;
            justify-content: flex-start;;
        }

        button {
            @include m.respond(phone) { 
                display: flex;
                flex-grow: 1;
                justify-content: center;
            }

            &:not(:last-child) {
                margin-right: 1rem;

                @include m.respond(phone) { 
                    margin-right: 0;
                }
            }
        }

    }


    &__site-title {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 300;
        display: inline-block;

        span {
            margin-left: .5rem;
        }

        @include m.respond(phone) {
            margin-bottom: 1rem;
            text-align: center;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
        span {
            font-weight: bold;
        }
    }

}