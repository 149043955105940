.input {
    &:not(:last-child) {
        margin-bottom: .6rem;
    }

    &__field {
        border: none;
        padding-left: .5rem;
        width: 100%;
    }

    &__label {
        font-style: italic;
        font-size: 1.2rem;
    }

    &__group {
        display: flex;
        align-items: stretch;
    }

    &__checkbox-container {
        flex-grow: 1;
        background-color: #e9ecef;
        padding: .5rem;
        line-height: 1;
    }

    &__checkbox {
        
    }
}
