@use '../abstracts/variables' as v;

.home { 
    &__buttons{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__btn {

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    &__link {
        color: v.$color-primary;
        text-decoration: underline;
        cursor: pointer;
    }
}