html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) { // width < 1200?
        font-size: 60%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) { // width < 900?
        font-size: 58%; //1 rem = 8px, 8/16 = 50%
    }
    
    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

#root {
    min-height: 100vh;
}

body {
    background-color: #cccccc;
    color: $color-white;
    background-image: url("../../assets/images/3px-tile.png");
}

.main {
    padding: 5rem 1.5rem 10rem 1.5rem;
}