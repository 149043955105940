@use '../../sass/abstracts/variables' as v;
@use '../../sass/abstracts/mixins' as m;

.markdown {

    &__copy {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
        cursor: pointer;
    }
    
    &__textarea {
        width: 50rem;
        height: 30rem;
        white-space: pre-wrap;

        @include m.respond(phone) {
            width: 100%;
        }

    }
}