@use "../../sass/abstracts/variables" as v;

.footer {
    background-color: v.$background-color-primary;
    padding: 1rem 1.5rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__socials {
        padding-left: 0;
        
        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }

        }
        
        a {
            text-decoration: underline;
            color: v.$color-white;
        }
    }

   
}