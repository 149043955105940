@use "../../sass/abstracts/variables" as v;

.card {
    position: relative;
    background-color: v.$background-color-primary;
    padding: 2rem;
    
    &:not(:last-child) {
        margin-bottom: 3rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        text-transform: uppercase;
        font-size: 1.9rem;
        font-weight: 300;
        margin-bottom: 1.5rem;
    }

    &__buttons {
        &:not(:last-child) {
            border-right: 1px solid white;
            margin-right: 1rem;
            padding-right: 1rem;
        }

        button {
            padding: .3rem .5rem;
            text-transform: uppercase;
            font-size: 1.2rem;
            color: v.$color-grey-dark;
            background-color: v.$color-grey-light-1;
            
            &:disabled {
                background-color: rgba(0,0,0,.2);
            }

            // &:not(:last-child) {
            //     margin-bottom: 1rem;
            // }
        }

    }

}