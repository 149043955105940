@use "../../sass/abstracts/variables" as v;

.button {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: .5rem 1rem;
    color: v.$color-grey-dark;
    background-color: v.$color-grey-light-1;

    &__icon {
        max-width: 20px;
        margin-right: .2rem;
        height: auto;
        fill: v.$color-primary;
        pointer-events: none;
        line-height: .8;

        &--start-again {
            transform: rotate(-180deg);
        }

    }
}